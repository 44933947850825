<template>
  <div>
    <v-skeleton-loader
      type="image"
      v-if="!value"
    />
    <div v-else>
      <v-card class="mb-4">
        <v-card-title>{{$t('t.Identification')}}</v-card-title>
        <v-card-text class="identification">
          <picker
            :disabled="!isNew"
            class="type mr-4"
            document-types="provider-types"
            :clearable="false"
            :rules="required"
            :label="$t('t.Type')"
            :selected-id.sync="computedProviderTypeId"
            :selected-document.sync="computedProviderType"
            :dense="false"
          />
          <v-text-field
            class="name"
            :label="$t('t.Name')"
            v-model="computedName"
            :rules="nameRules"
            :clearable="clearable"
          />
          <v-checkbox
            :label="$t('t.Active')"
            v-model="computedIsActive"
            class="mr-4 is-active"
          />
        </v-card-text>
      </v-card>
      <v-card v-if="computedProviderTypeId">
        <v-card-title>{{computedProviderTypeName}}</v-card-title>
        <v-card-text>
          <component
            v-if="computedProviderTypeId"
            :is="computedProviderValueComponentName"
            :document.sync="computedValue"
            :is-new="isNew"
            :is-dirty="isDirty"
            @refresh="refreshCache"
          />
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>

export default {
  components: {
    Ar24: () => import('./provider-types/ar24'),
    Cesar: () => import('./provider-types/cesar'),
    Google: () => import('./provider-types/google'),
    Groupama: () => import('./provider-types/groupama'),
    Imap: () => import('./provider-types/imap'),
    Maileva: () => import('./provider-types/maileva'),
    Microsoft: () => import('./provider-types/microsoft'),
    OpenIdConnect: () => import('./provider-types/open-id-connect'),
    Picker: () => import('@/components/picker'),
    Smtp: () => import('./provider-types/smtp')
  },
  data () {
    return {
      selectedProviderType: {},
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      dataIsActive: true,
      dataName: null,
      dataValue: null,
      required: [v => (!!v && !this.lodash.isEqual(v, { id: null })) || this.$t('t.IsRequired')]
    }
  },
  computed: {
    computedProviderTypeName () {
      return this.selectedProviderType?.name
    },
    computedProviderType: {
      get () {
        return this.selectedProviderType
      },
      set (v) {
        this.selectedProviderType = v
      }
    },
    computedProviderValueComponentName () {
      return this.computedProviderTypeId
    },
    nameRules () {
      return [
        v => !!v || this.$t('t.IsRequired'),
        v => (v ?? '').length <= 100
      ]
    },
    computedIsActive: {
      get () {
        return this.dataIsActive
      },
      set (v) {
        this.dataIsActive = v
        this.dataDocumentEmit()
      }
    },
    computedName: {
      get () {
        return this.dataName
      },
      set (v) {
        this.dataName = v
        this.dataDocumentEmit()
      }
    },
    computedProviderTypeId: {
      get () {
        return this.dataValue?.ty
      },
      set (v) {
        if (!this.dataValue) {
          this.dataValue = {
            ty: v
          }
        }
        this.dataValue.ty = v

        this.dataDocumentEmit()
      }
    },
    computedValue: {
      get () {
        return this.dataValue
      },
      set (v) {
        this.dataValue = v
        this.dataDocumentEmit()
      }
    }
  },
  methods: {
    emitDocument () {
      const doc = {
        isActive: this.dataIsActive,
        name: this.dataName,
        value: this.dataValue
      }

      if (!this.lodash.isEqual(this.value, doc)) {
        this.$emit('input', this.lodash.cloneDeep(doc))
      }
    },
    refreshCache () {
      this.$emit('refresh')
    }
  },
  props: {
    clearable: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    },
    isDirty: {
      type: Boolean,
      default: false
    },
    value: Object
  },
  watch: {
    value: {
      immediate: true,
      handler (v) {
        this.dataIsActive = v?.isActive || false
        this.dataName = v?.name
        this.dataValue = v?.value
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.identification
  display grid
  grid-template-columns 100px 20% auto
  grid-template-areas 'is-active type name'

.is-active
  gred-area is-active

.name
  grid-area name

.type
  grid-area type
</style>
